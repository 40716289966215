
.virus__overlay__page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #000000b8;
    min-height: 50vh;
    margin: 2rem;

    button {
        width: 100px;
        height: 50px;
        background-color: #ffcb1c;
        border: 0;
        box-sizing: border-box;
        font-size: 2rem;
        font-family: 'Black Ops One', Arial, sans-serif;
    }
}
