@import './overlay-placeholder.scss';

.game-details {
    @extend %overlay;

    position: absolute;
    width: 100%;
    height: 400px;
    bottom: -350px;
    top: unset;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50px auto 100px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: bottom 2s;


    &--open {
        bottom: 0px;

        button::before {
            transform: rotate(180deg);
        }
    }

    &__slider {
        width: 100%;
        color: white;
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 1;


        button::before {
            content: '';
            width: 100%;
            height: 20px;
            background-image: url('../images/chveron-up.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            transition: rotate 2s;
            filter: brightness(0) invert(1); // make the image white
        }
        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 50px;
            background: none;
            border: none;
            color: white;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__town1 {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    &__town2 {
        grid-column-start: 2;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    &__combined {
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 3;
        width: 100%;
        padding: 10px;
    }
}
