@font-face {
  font-family: 'Black Ops One';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/black-ops-one-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/black-ops-one-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/black-ops-one-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/black-ops-one-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/black-ops-one-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/black-ops-one-v20-latin-regular.svg#BlackOpsOne') format('svg'); /* Legacy iOS */
}

$white: #fff;
$break: 100000px;
$background: rgb(78, 142, 168);

* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	overflow-x: hidden;
	font-family: open-sans, Helvetica, sans-serif;
	background-color: $background;
	background-image: url('../images/page-bg.png');
	background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Black Ops One', Arial, sans-serif;
}

h1 {
	font-weight: bold;
	text-align: center;
	color: white;
	font-size: 2em;
}

h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}


.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.map__container {
	position: relative;
	grid-column: 1;
	grid-row: 1;
	width: 100%;
	height: 800px;
	max-height: 100vh;
	padding: 0;
	margin: 0;
	overflow: hidden;
}

.map {
	width: 100%;
	max-height: 100vh;
	padding: 0;
	margin: 0;
	box-sizing: border-box;

	canvas {
		max-height: 100%;
	}
}

.counter {
	position: absolute;
	top: 0;
	left: 4px;
	color: $white;
}

.hidden {
    display: none !important;
}

@media(min-width: 1000px) {
}

@import './overlay.scss';
@import './intro.scss';
@import './controls.scss';
@import './virus-select.scss';
@import './setup.scss';
@import './toggle-btn.scss';
@import './endgame.scss';
@import './game-details.scss';
@import './intro-dialog.scss';
@import './virus-information.scss';

