$border-colour: $black !default;
$yellow: #fdcf30;
$padding: 30px;
$black: #000;
$white: #fff;

.intro-dialog {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 800;
	width: 100vw;
	background: lighten($background, 20%);
	border: 1px solid $border-colour;
	box-shadow: 0 2px 2px 1px rgba($black, 0.6);

	section {
		padding: 0 $padding $padding;
	}

	header {
		padding: 5px $padding;
		background-color: $yellow;
	}

	&__close {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 50px;
		height: 50px;
		margin: 0 4px;
		font-size: 0;
		line-height: 2.5rem;
		background-color: $background;
		background-image: url('../images/close.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 130%;
		border: 2px solid $white;
		border-radius: 50%;
	}

	&__logos {
		background: white;
		text-align: center;

		img {
			max-width: 100%;
		}
	}
}

.legend {
	// https://stackoverflow.com/a/6536025/4187995
	padding: 0;

	h3 {
		margin-bottom: 2px;
	}

	figure {
		display: table;
		width: 1px;
		margin: 0;
		color: $white;
		background: $black;
		border: 1px solid $black;
	}

	img,
	figcaption {
		display: table-row;
	}
}

@media(min-width: 800px) {
	.intro-dialog {
		top: 10vh;
		left: 10vw;
		width: 80vw;
		height: auto;
	}
}

@media(min-width: 1000px) {
	.intro-dialog {
		top: 20vh;
		left: 20vw;
		width: 60vw;
	}
}
