@import './overlay.scss';

%input_section {
    @extend .overlay__panel__half;

    h2 {
		text-align: center;
	}
}

.setup {
	&__overlay {
	    @extend .overlay;
        
        background: #00000087;
		flex-wrap: wrap;
	}


	&__town {
		@extend %input_section;
	}

	&__general {
		@extend %input_section;
		align-items: center;
	}

	&__health-label {
	    width: 4.5rem;
	    text-align: right;
    }


}
