@import './overlay-placeholder.scss';

.endgame__overlay {
    @extend %overlay;
    background-color: #000000b8;
    flex-wrap: wrap;

    h2 {
        width: 100%;
        text-align: center;
        margin-bottom: 0.2rem;
    }

}

.endgame__score {
    font-size: 0;

    $starHeight: 20px;
    &::before {
        content: '';
        background-image: url('../images/star.svg');
        background-size: contain;
        background-repeat: repeat-x;
        display: inline-block;
        height: $starHeight;
        width: $starHeight;
    }

    &[data-score = "1"]::before {
        width: $starHeight;
    }
    &[data-score = "2"]::before {
        width: $starHeight * 2;
    }
    &[data-score = "3"]::before {
        width: $starHeight * 3;
    }
    &[data-score = "4"]::before {
        width: $starHeight * 4;
    }
    &[data-score = "5"]::before {
        width: $starHeight * 5;
    }


}

.restart-btn {
    background-image: url('../images/replay.svg');
    background-color: white;
    border-radius: 50%;
}
