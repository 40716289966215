@import './overlay-placeholder.scss';

%virus__tile {
	width: 483px;
	height: 276px;
	font-size: 4em;
	text-align: center;
	border: 0;
	color: white;
	margin: 10px;
}

.virus {
	&__overlay {
	    @extend %overlay;
        flex-direction: column;

		h2 {
			margin: 0;
			font-size: 3em;
			text-align: center;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	&__corona {
		@extend %virus__tile;
		background-image: url('../images/corona.png');
	}
	&__gastro {
		@extend %virus__tile;
		background-image: url('../images/gastro.png');
	}
	&__respiratory {
		@extend %virus__tile;
		background-image: url('../images/respiratory.png');
	}

}
