@import './overlay-placeholder.scss';

.overlay__panel {
    box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 10px;
}

.overlay__panel__half {
    @extend .overlay__panel;
	width: 50%;
}

.overlay {
    @extend %overlay;
}
