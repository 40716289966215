$background: rgb(78, 142, 168);
$heading-bg: rgb(253, 207, 48);
$white: #fff;
$red: rgb(238, 52, 56);
$black: rgb(0, 0, 0);

.controls {
	background-color: $background;
	box-sizing: border-box;

	form {
		background-color: $background;
	}

	fieldset {
		padding: 0;
		margin: 0;
		border: none;
	}

	label,
	legend {
		margin: 0;
		font-size: 1rem;
		font-weight: bold;
		color: $white;
		text-transform: uppercase;
	}
}

.input {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	label {
	    flex-grow: 5;
    }
    input {
        flex-grow: 4;
    }
}

.btn {
	display: inline-block;
	width: 50px;
	height: 50px;
	margin: 10px;
	font-size: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: cover;
	border: none;
}

.start-btn {
	background-image: url('../images/go.png');

	&.btn--on {
		background-image: url('../images/stop.png');
	}
}

.reload-btn {
	background-color: $red;
	background-image: url('../images/reload.svg');
	background-position: center;
	background-size: 70%;
	border: 3px $white solid;
	border-radius: 50%;

	&--active {
		animation: spin 2s infinite linear;
	}

	&:disabled {
		background-color: darken($red, 30%);
		opacity: 0.7;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


