// Based on https://webdesign.tutsplus.com/tutorials/toggle-switch-component-with-css-checkbox-hack--cms-35011
.checkbox {
  --white: #e8e9ed;
  --gray: #434257;
  --blue: #18172c;
  --green: #00d084;
  --pink: #ff4b77;

	label {
		cursor: pointer;
	}

	[type="checkbox"] {
		position: absolute;
		left: -9999px;
	}

	& label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	& span:last-child {
		position: relative;
		width: 50px;
		height: 26px;
		border-radius: 15px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
		background: var(--gray);
		transition: all 0.3s;
	}

	& span:last-child::before,
	& span:last-child::after {
		content: "";
		position: absolute;
	}

	& span:last-child::before {
		left: 1px;
		top: 1px;
		width: 24px;
		height: 24px;
		background: var(--white);
		border-radius: 50%;
		z-index: 1;
		transition: transform 0.3s;
	}

	& span:last-child::after {
		top: 50%;
		right: 8px;
		width: 12px;
		height: 12px;
		transform: translateY(-50%);
		background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
		background-size: 12px 12px;
	}

	& [type="checkbox"]:checked + label span:last-child {
		background: var(--green);
	}

	& [type="checkbox"]:checked + label span:last-child::before {
		transform: translateX(24px);
	}

	& [type="checkbox"]:checked + label span:last-child::after {
		width: 14px;
		height: 14px;
		/*right: auto;*/
		left: 8px;
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/checkmark-switcher.svg);
		background-size: 14px 14px;
	}
}
